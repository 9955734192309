import React from 'react';

// import { Link } from '../../subComponents/Link';
import { LinkContainer } from '../../../styles/typography';
import { ContentContainer, Title, Sub, Table, TableHeader, TableCell, TableRow } from './PrivacyPolicyStyles';

export const PrivacyPolicyContainer = () => {
  return (
    <ContentContainer>
      <Title>Privacy Policy</Title>
      <Sub>Effective: May 1, 2024</Sub>
      <div>
        <h3 hidden>privacy policy</h3>
        <p>
          We appreciate that you are trusting us with information that is important to you, and we are transparent about
          how we use it.
        </p>
        <p>
          Here we describe the privacy practices for our website. You will learn about the data we collect, how we use
          it, the controls we give you over your information, and the measures we take to keep it safe.
        </p>
        <p>Specifically, we’ll cover:</p>
        <ul>
          <li>
            <LinkContainer link="#information-we-collect">Information We Collect</LinkContainer>
          </li>
          <li>
            <LinkContainer link="#how-we-use-information">How We Use Information</LinkContainer>
          </li>
          <li>
            <LinkContainer link="#how-information-is-shared">How Information Is Shared</LinkContainer>
          </li>
          <li>
            <LinkContainer link="#your-rights-to-access-and-control-your-personal-data">
              Your Rights To Access And Control Your Personal Data
            </LinkContainer>
          </li>
          <li>
            <LinkContainer link="#data-retention">Data Retention</LinkContainer>
          </li>
          <li>
            <LinkContainer link="#analytics-and-advertising-services-provided-by-others">
              Analytics And Advertising Services Provided By Others
            </LinkContainer>
          </li>
          <li>
            <LinkContainer link="#information-security">Information Security</LinkContainer>
          </li>
          <li>
            <LinkContainer link="#our-international-operations-and-data-transfers">
              Our International Operations And Data Transfers
            </LinkContainer>
          </li>
          <li>
            <LinkContainer link="#changes-to-this-policy">Changes To This Policy</LinkContainer>
          </li>
          <li>
            <LinkContainer link="#cookie-use">Cookie Use</LinkContainer>
          </li>
          <li>
            <LinkContainer link="#who-we-are-and-how-to-contact-us">Who We Are And How To Contact Us</LinkContainer>
          </li>
        </ul>
        <article id="information-we-collect">
          <h3>INFORMATION WE COLLECT</h3>
          <p>When you use our website, we collect the following types of information.</p>
          <p>INFORMATION YOU PROVIDE US</p>
          <p>CONTACT INFORMATION</p>
          <p>
            When you request information from the website (such as by filling out a “contact us” web form), we require
            you to enter some information, such as your name, email address, and message. Depending on the nature of
            your request, we may ask you to provide us with additional information,such as company annual revenues,
            number of employees, or industry. If you apply for a job with the Company, we may also require you to submit
            additional personal information as well as a resume or curriculum vitae.
          </p>
          <p>INFORMATION WE RECEIVE FROM YOUR USE OF OUR WEBSITE</p>
          <p>USAGE INFORMATION</p>
          <p>
            When you access or use our website, we receive certain usage data. This includes information about your
            interaction with the website, for example, when you view or search content.
          </p>
          <p>
            We also collect data about the devices and computers you use to access the website, including anonymized IP
            addresses, browser type, language, operating system, the referring web page, pages visited, and cookie
            information.
          </p>
        </article>
        <article id="how-we-use-information">
          <h3>HOW WE USE INFORMATION</h3>
          <p>We use the information we collect for the following purposes.</p>
          <p>PROVIDE, IMPROVE, AND DEVELOP THE WEBSITE</p>
          <p>
            Using the information we collect, we are able to honor our website Terms of Use contract with you. For
            example, we need to use your information to fulfill your requests and to give you customer support. We also
            use the information we collect to improve the website. For example, we use the information to troubleshoot
            and protect against errors; conduct research; and develop new features and services.
          </p>
          <p>COMMUNICATE WITH YOU</p>
          <p>
            We use your information when needed to respond to you when you contact us. We may also use your information
            to promote new features or products that we think you would be interested in. You can control marketing
            communications by changing your privacy settings or via the “Unsubscribe” link in an email.
          </p>
          <p>PROMOTE SAFETY AND SECURITY</p>
          <p>
            We use the information we collect to promote the safety and security of the website, our visitors, and other
            parties. For example, we may use the information to authenticate users, protect against fraud and abuse,
            respond to a legal request or claim, conduct audits, and enforce our terms and policies.
          </p>
          <p>
            We use cookies and similar technologies for the purposes described above. For more information, please read
            our Cookie Use statement below.
          </p>
          <p>
            For personal data subject to the GDPR, we rely on several legal bases to process the data. These include
            when you have given your consent, which you may withdraw at any time using your account settings and other
            tools; when the processing is necessary to perform a contract with you, like the Terms of Use; and our
            legitimate business interests, such as in improving, personalizing, and developing the Services, marketing
            new features or products that may be of interest, and promoting safety and security as described above.
          </p>
        </article>
        <article id="how-information-is-shared">
          <h3>HOW INFORMATION IS SHARED</h3>
          <p>We do not share your personal information except in the limited circumstances described below.</p>
          <p>FOR EXTERNAL PROCESSING</p>
          <p>
            We transfer information to our corporate affiliates, service providers, and other partners who process it
            for us, based on our instructions, and in compliance with this policy and any other appropriate
            confidentiality and security measures. These partners provide us with services globally, including for
            customer support, information technology, sales, marketing, data analysis, and research.
          </p>
          <p>FOR LEGAL REASONS OR TO PREVENT HARM</p>
          <p>
            We may preserve or disclose information about you to comply with a law, regulation, legal process, or
            governmental request; to assert legal rights or defend against legal claims; or to prevent, detect, or
            investigate illegal activity, fraud, abuse, violations of our terms, or threats to the security of the
            website or the physical safety of any person.
          </p>
          <p>
            Please note: Our policy is to notify you of legal process seeking access to your information, such as search
            warrants, court orders, or subpoenas, unless we are prohibited by law from doing so. In cases where a court
            order specifies a non-disclosure period, we provide delayed notice after the expiration of the
            non-disclosure period. Exceptions to our notice policy include exigent or counterproductive circumstances,
            for example, when there is an emergency involving a danger of death or serious physical injury to a person.
          </p>
          <p>
            If we are involved in a merger, acquisition, or sale of assets, we will continue to take measures to protect
            the confidentiality of personal information and give affected users notice before transferring any personal
            information to a new entity.
          </p>
        </article>
        <article id="your-rights-to-access-and-control-your-personal-data">
          <h3>YOUR RIGHTS TO ACCESS AND CONTROL YOUR PERSONAL DATA</h3>
          <p>
            We give you tools to access and control your personal data, as described below, regardless of where you
            live. If you live in the European Economic Area, United Kingdom, and Switzerland (the “Designated
            Countries”), you have a number of legal rights with respect to your information, which your tools allow you
            to exercise, as outlined below.
          </p>
          <p>
            Accessing, Exporting, Editing, and Deleting Data. Your privacy settings let you access, export, change, and
            delete your personal information if you wish.
          </p>
          <p>
            If you choose to delete your data, please note that while most of your information will be deleted within 30
            days, it may take up to 90 days to delete all of your information, like data stored in our backup systems.
            This is due to the size and complexity of the systems we use to store data. We may also preserve data for
            legal reasons or to prevent harm, including as described in the How Information Is Shared section.
          </p>
          <p>
            Objecting to Data Use. We give you tools to control our data use. For example, through your privacy
            settings, you can limit the notifications you receive from us.
          </p>
          <p>
            If you live in a Designated Country, in certain circumstances, you can object to our processing of your
            information based on our legitimate interests, including as described in the How We Use Information section.
            You have a general right to object to the use of your information for direct marketing purposes. Please see
            your privacy settings to control our marketing communications to you about Oqcam products. Please also
            review our <LinkContainer link="#cookie-use">Cookie Use</LinkContainer> statement below for your options to
            control how we and our partners use cookies and similar technologies for advertising.
          </p>
          <p>
            Restricting or Limiting Data Use. In addition to the privacy controls that we offer, if you reside in a
            Designated Country, you can seek to restrict our processing of your data in certain circumstances. Please
            note that you can always delete your data at any time.
          </p>
          <p>
            If you need further assistance regarding your rights, please contact our Data Protection Officer at&nbsp;
            <LinkContainer link="mailto:privacy@oqcam.com?subject=Oqcam Data Protection Officer Contact" isHref>
              privacy@oqcam.com
            </LinkContainer>
            , and we will consider your request in accordance with applicable laws. If you reside in a Designated
            Country, you also have a right to lodge a complaint with your local data protection authority.
          </p>
        </article>
        <article id="data-retention">
          <h3>DATA RETENTION</h3>
          <p>
            We keep your personal information, like your name and email address, for as long as we need it to serve you.
            In some cases, when you give us information for a particular feature of the website, we delete the data
            after it is no longer needed for the feature. We keep other information, like information used to sign up
            for our newsletter, until you use tools or contact us to delete the data because we use this data to provide
            you with what you’ve requested. We also keep information about you and your use of the website for as long
            as necessary for our legitimate business interests, for legal reasons, and to prevent harm, including as
            described in the How We Use Information and How Information Is Shared sections.
          </p>
        </article>
        <article id="analytics-and-advertising-services-provided-by-others">
          <h3>ANALYTICS AND ADVERTISING SERVICES PROVIDED BY OTHERS</h3>
          <p>
            We work with partners who provide us with analytics and advertising services. This includes helping us
            understand how users interact with the website, serving ads on our behalf across the internet, and measuring
            the performance of those ads. These companies may use cookies and similar technologies to collect
            information about your interactions with our website and other websites and applications. To learn more and
            about your privacy choices, please read our Cookie Use statement.
          </p>
        </article>
        <article id="information-security">
          <h3>INFORMATION SECURITY</h3>
          <p>
            We work hard to keep your data safe. We use a combination of technical, administrative, and physical
            controls to maintain the security of your data. No method of transmitting or storing data is completely
            secure, however. If you have a security-related concern, please contact{' '}
            <LinkContainer link="mailto:websitesupport@oqcam.com?subject=Oqcam site security concern" isHref>
              websitesupport@oqcam.com
            </LinkContainer>
            .
          </p>
        </article>
        <article id="our-international-operations-and-data-transfers">
          <h3>OUR INTERNATIONAL OPERATIONS AND DATA TRANSFERS</h3>
          <p>
            We operate internationally and may transfer information to the United States and other countries for the
            purposes described in this policy.
          </p>
          <p>
            We are responsible for personal information that we transfer to others who process it on our behalf as
            described in the How Information Is Shared section. If you have a complaint about our information transfers,
            please contact us. You can also refer a complaint to our chosen independent dispute resolution body as noted
            in the{' '}
            <LinkContainer link="#who-we-are-and-how-to-contact-us">Who We Are And How To Contact Us</LinkContainer>{' '}
            section of this policy.
          </p>
          <p>
            Please note that the countries where we operate may have privacy and data protection laws that differ from,
            and are potentially less protective than, the laws of your country. You agree to this risk when you submit
            personal information on our website and click “I agree” to data transfers, or when you consent to the use of
            cookies, irrespective of which country you live in. For a list of the locations where we have offices,
            please see our company information <LinkContainer link="/company/">here</LinkContainer>. If you later wish
            to withdraw your consent, you can delete your personal data as described in the Your Rights To Access and
            Control Your Personal Data section.
          </p>
        </article>
        <article id="changes-to-this-policy">
          <h3>CHANGES TO THIS POLICY</h3>
          <p>
            We will notify you before we make material changes to this policy and give you an opportunity to review the
            revised policy before deciding if you would like to continue to use the website. You can review previous
            versions of the policy in our archive.
          </p>
        </article>
        <article id="cookie-use">
          <h3>COOKIE USE</h3>
          <p>
            We and our partners use cookies and similar technologies on our websites. Here we explain these
            technologies, why we use them, and the choices you have.
          </p>
        </article>
        <article id="technologies-we-use">
          <h3>TECHNOLOGIES WE USE</h3>
          <p>
            Like many websites, we use cookies, pixels (aka “web beacons”), and local storage to remember things about
            you so that we can provide you with a better experience.
          </p>
          <ul>
            <li>
              Cookies are small data files stored on your browser or device. They may be served by the entity that
              operates the website you are visiting (“first-party cookies”) or by other companies (“third-party
              cookies”).
            </li>
            <li>
              For example, we partner with third-party analytics providers, like Google, which set cookies when you
              visit our website. This helps us understand how you are using our website so that we can improve it.
            </li>
            <li>
              Pixels or web beacons are small images on a web page or in an email. Pixels collect information about your
              browser or device and can set cookies.
            </li>
            <li>
              Local storage allows data to be stored locally on your browser or device and includes HTML5 local storage
              and browser cache.
            </li>
          </ul>
        </article>
        <article id="our-use-of-these-technologies">
          <h3>OUR USE OF THESE TECHNOLOGIES</h3>
          <p>
            See our <a href={'/cookie-policy/'}>cookie page</a> for a comprehensive list of all cookies and to make
            adjustments to consent.
          </p>
          <Table>
            <tbody>
              <TableRow>
                <TableHeader>CATEGORY OF USE</TableHeader>
                <TableHeader>PURPOSE OF THE USE</TableHeader>
              </TableRow>
              <tr>
                <TableCell>Preferences</TableCell>
                <TableCell>
                  To help us remember your settings and preferences, like your preferred language or the country you are
                  in, so that we can provide you with a more personalized experience.
                </TableCell>
              </tr>
              <tr>
                <TableCell>Analytics & Research</TableCell>
                <TableCell>
                  To help us understand how you are using the website so that we can make it better, faster, and safer.
                </TableCell>
              </tr>
            </tbody>
          </Table>
        </article>
        <article id="your-choices">
          <h3>YOUR CHOICES</h3>
          <p>
            You have a number of options to control or limit how we and our partners use cookies and similar
            technologies.
          </p>
          <ul>
            <li>
              When you first visit our website, you will be notified that we use cookies and similar technologies and
              given the opportunity to learn more and/or to consent to their use. We will not begin using these
              technologies until we receive your consent.
            </li>
            <li>
              You may withdraw or modify your consent to use cookies at any time by{' '}
              <LinkContainer link="/cookie-policy/">changing your settings on our cookies page</LinkContainer>. If you
              disable all cookies on our website, however, some of the features of the website may not function
              properly.
            </li>
            <li>
              You can adjust your web browser settings to clear or decline cookies globally or on a per-website basis.
            </li>
            <li>
              To prevent your data from being used by Google Analytics, you can also install&nbsp;
              <LinkContainer link="//tools.google.com/dlpage/gaoptout">Google’s opt-out browser add-on</LinkContainer>.
            </li>
            <li>
              Check your mobile device for settings that control ads based on your interactions with the applications on
              your device. For example, on your iOS device, enable the “Limit Ad Tracking” setting, and on your Android
              device, enable the “Opt out of Ads Personalization” setting.
            </li>
          </ul>
        </article>
        <article id="who-we-are-and-how-to-contact-us">
          <h3>WHO WE ARE AND HOW TO CONTACT US</h3>
          <p>
            If you have questions, suggestions, or concerns about this policy, or about our use of your information,
            please contact us at{' '}
            <LinkContainer link="mailto:legal@oqcam.com?subject=Oqcam Privacy Policy" isHref>
              legal@oqcam.com
            </LinkContainer>
            .
          </p>
          <p>
            If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please
            contact our U.S.-based third party dispute resolution provider (free of charge) at&nbsp;
            <LinkContainer link="//feedback-form.truste.com/watchdog/request">
              https://feedback-form.truste.com/watchdog/request
            </LinkContainer>
            .
          </p>
          <p>
            If you live in the European Economic Area, United Kingdom, or Switzerland, then Oqcam BV controls
            your personal data. If you are seeking to exercise any of your statutory rights, please contact our Data
            Protection Officer at&nbsp;
            <LinkContainer link="mailto:privacy@oqcam.com?subject=Oqcam Data Protection Officer Contact" isHref>
              privacy@oqcam.com
            </LinkContainer>
            . You may also contact us at:
          </p>
          <p>
            Oqcam BV
            <br /> Bomastraat 10A
            <br /> 9000 Gent
            <br /> Belgium
          </p>
        </article>
      </div>
    </ContentContainer>
  );
};
